import React from 'react';
import { graphql } from 'gatsby';
import cx from 'classnames';
import * as styles from './styles/blog.module.scss';
import {
  IPageDetails,
  IPageContext,
  IBlogList,
  IBlogItem,
} from '../interfaces';
import { BlogCard, TitleArea } from '../components';
import withMeta from '../hocs/withMeta';

interface IProps {
  data: {
    contentfulPage: IPageDetails;
    allContentfulBlog: IBlogList;
    allContentfulBlogTags: { edges: Array<{ node: { tag: string } }> };
  };
  pageContext: IPageContext;
}

interface IState {
  open: boolean;
  filteredTags: string[];
  tags: string[];
  blogs: IBlogItem[];
  allBlogs: IBlogItem[];
}

class Blog extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { data } = this.props;
    const { edges } = data.allContentfulBlog;
    // const { edges: blogTags } = data.allContentfulBlogTags;
    const blogs = edges.map((edge) => edge.node);
    // const allTags = blogTags.map(edge => edge.node.tag);

    const tagHelper: { [key: string]: boolean } = {};
    blogs.forEach((elem) => {
      if (elem.tags) {
        elem.tags.forEach((item) => {
          tagHelper[item.tag] = true;
        });
      }
    });
    const tags = Object.keys(tagHelper).map((key, _) => {
      return key;
    });

    this.state = {
      open: true,
      filteredTags: [],
      tags,
      blogs,
      allBlogs: blogs,
    };
  }

  changeFilteredTags = (tag: string) => {
    const { filteredTags } = this.state;
    const index = filteredTags.indexOf(tag);
    if (index > -1) {
      filteredTags.splice(index, 1);
      this.setState({ filteredTags });
    } else {
      filteredTags.push(tag);
      this.setState({ filteredTags });
    }

    this.changeBlogs();
  };

  changeBlogs = () => {
    const { filteredTags, allBlogs } = this.state;
    if (filteredTags.length === 0) {
      this.setState({ blogs: allBlogs });
    } else {
      const newBlog = allBlogs.filter((blog) => {
        if (blog.tags === null) {
          return false;
        } else {
          for (const tag of blog.tags) {
            if (filteredTags.indexOf(tag.tag) > -1) {
              return true;
            }
          }
          return false;
        }
      });
      this.setState({ blogs: newBlog });
    }
  };

  render() {
    const { data, pageContext } = this.props;
    const { tags, blogs, filteredTags } = this.state;
    const { title } = data.contentfulPage;

    return (
      <div>
        <TitleArea title={title}>
          <div
            className={cx(
              'columns',
              'is-multiline',
              'is-variable',
              'is-2',
              'is-mobile'
            )}
          >
            {tags.map((tag) => (
              <div key={tag} className={cx('column', 'is-narrow')}>
                <button
                  onClick={() => this.changeFilteredTags(tag)}
                  className={
                    filteredTags.indexOf(tag) > -1
                      ? styles.selectedButton
                      : styles.tagButton
                  }
                >
                  {tag}
                </button>
              </div>
            ))}
          </div>
        </TitleArea>
        <BlogCard blogs={blogs} langPath={pageContext.langPath} />
      </div>
    );
  }
}

export const query = graphql`
  query BlogQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...PageDetails
    }
    allContentfulBlog(
      filter: { node_locale: { eq: $locale }, title: { ne: "ekki eyða" } }
      sort: { fields: date, order: DESC }
    ) {
      ...BlogList
    }
    allContentfulBlogTags(filter: { tag: { ne: "ekki eyða" } }) {
      edges {
        node {
          tag
        }
      }
    }
  }
`;

export default withMeta(Blog);
